import { apiClientV2 } from "../../api/apiClient";
import { useMutation } from "../common/useMutation";

export function useProcessedSettlement({ refetch, setIsAllChecked }) {
  const { mutate: processMutate, isProcessLoading } = useMutation(
    async ({ settleIds, payoutDate }) => {
      return await apiClientV2({
        url: "/settlements/process",
        method: "post",
        data: {
          settlementIds: settleIds,
          payoutDate,
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        // if (data.errorMessages) {
        //   alert(data.errorMessages);
        //   return;
        // }

        setIsAllChecked(false);
        refetch();
        alert(
          "정산대행 요청이 완료되었습니다.\n정산대행 요청은 취소가 불가능합니다."
        );
      },
      onError: ({ response }) => {
        const { data } = response;
        const { message, errorDetails } = data;

        alert(`${message}\n${errorDetails[0].reason}`);
      },
    }
  );

  return { processMutate, isProcessLoading };
}

import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});
export const apiClientV2 = axios.create({
  baseURL: process.env.REACT_APP_API_2,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

export const apiClientTempTemp = axios.create({
  baseURL: process.env.REACT_APP_API_TEMP_TEMP,
  headers: {
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VySWQiLCJpc0FjY2Vzc1Rva2VuIjp0cnVlLCJpc3MiOiJpdm91Y2hlci5rciIsImlkIjoiMSIsImV4cCI6MTY4OTE0NjcxNSwic3RhZmZUeXBlIjoiQ0VPIiwiaWF0IjoxNjczNTk0NzE1fQ._ayHCDTsp6HcQ_oejsO0rGaYl_whMKPgS1gq9Ke5I5o`,
  },
});

export const tempApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_TEMP,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});
